/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import Range from '../ui/Range';

import { kFormatter } from './helpers';
import { contains } from '../../utils';

//    int :: String -> Int
const int = (string) => parseInt(string, 10);

const MotoringTerms = ({
  price,
  annualMileage,
  contractLength,
  initialMonths,
  mileages,
  shortTermMileages,
  terms,
  shortTerms,
  initialPayments,
  initialPayment,
  shortTermInitialPaymentsByTerm,
  defaultInitialPaymentMonthsByTerm,
  isPersonal,
  hasShortTerm,
  hasStandardTerm,
}) => {
  const dispatch = useDispatch();

  const [shortTerm] = useState(shortTerms.indexOf(contractLength) > -1);

  const handleToggle = (event) => {
    event.preventDefault();
    const term = shortTerm ? 48 : 12;
    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search);

    params.set('term', term);
    // eslint-disable-next-line no-restricted-globals
    Turbolinks.visit(`${location.pathname}?${params}`);
  };

  const shortTermInitialPaymentsTotal = shortTermInitialPaymentsByTerm['12'];
  const shortTermInitialPayments = shortTermInitialPaymentsByTerm[contractLength];

  const applicableTerms = shortTerm ? shortTerms : terms;
  const applicableMileages = shortTerm ? shortTermMileages : mileages;
  const applicableInitialPayments = shortTerm ? shortTermInitialPayments : initialPayments;

  useEffect(() => {
    if (!contains(contractLength, applicableTerms)) {
      dispatch({
        type: 'STORE_CONTRACT_LENGTH',
        payload: applicableTerms[0],
      });
    }

    if (shortTerm) {
      if (!contains(annualMileage, shortTermMileages)) {
        dispatch({
          type: 'STORE_ANNUAL_MILEAGE',
          payload: shortTermMileages[0],
        });
      }
      if (!contains(initialMonths, shortTermInitialPayments)) {
        dispatch({
          type: 'STORE_INITIAL_MONTHS',
          payload: defaultInitialPaymentMonthsByTerm[contractLength],
        });
      }
    }
  }, [shortTerm, contractLength]);

  return (
    <>
      <header className="drv-deal-sidebar__option-title drv-block drv-mb-3">
        <span className="drv-text-bold" data-controller="tooltip" data-tooltip-placement-value="left">
          <span className="drv-info" data-tooltip-target="button" />
          Contract Length:
          <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip" data-tooltip-placement-value="left">
            This is simply the length of your lease
            <div className="drv-tooltip__arrow" data-popper-arrow />
          </div>
        </span>
        <span className=""> (months) </span>
        <span className="drv-deal-sidebar__option-value drv-text-bold drv-text-gold-darker">
          {contractLength} months
        </span>
      </header>
      <div className="drv-radio-buttons drv-mb-6">
        {applicableTerms.map((term) => (
          <Fragment key={term}>
            <label className="drv-radio-button">
              <input
                type="radio"
                name="term"
                id={`term_${term}`}
                value={term}
                checked={term === contractLength}
                onChange={(event) => {
                  dispatch({
                    type: 'STORE_CONTRACT_LENGTH',
                    payload: int(event.target.value),
                  });
                }}
              />
              <span className="drv-radio-button__label">{term}</span>
            </label>
          </Fragment>
        ))}
      </div>
      {hasStandardTerm && hasShortTerm && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className="d-block drv-mb-6 drv-link drv-text-medium-weight drv-deal-sidebar-term-toggle"
          href="#"
          onClick={(e) => handleToggle(e)}
        >
          {shortTerm ? 'Long term leasing options available' : 'Short term leasing options available'}
        </a>
      )}

      <header className="drv-deal-sidebar__option-title drv-block drv-mb-3 drv-mt-1">
        <span className="drv-text-bold" data-controller="tooltip" data-tooltip-placement-value="left">
          <span className="drv-info" data-tooltip-target="button" />
          Annual miles:
          <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip">
            Your annual mileage allowance is multiplied by the length of your contract to give you a total mileage
            allowance. Please note that an Excess mileage fee may apply if you exceed your total contract mileage, this
            will be detailed on your Finance Agreement. We will base your quotes on an appropriate mileage for your
            needs.
            <div className="drv-tooltip__arrow" data-popper-arrow />
          </div>
        </span>
        <span className="drv-deal-sidebar__option-value drv-text-bold drv-text-gold-darker">
          {' '}
          {annualMileage} miles
        </span>
      </header>
      <Range
        data={applicableMileages}
        selected={annualMileage}
        onChange={(value) => {
          dispatch({
            type: 'STORE_ANNUAL_MILEAGE',
            payload: int(applicableMileages[value - 1]),
          });
        }}
        labels={applicableMileages.map((mileage) => kFormatter(mileage))}
      />

      <header
        className="drv-deal-sidebar__option-title drv-block drv-mb-3"
        style={{ marginTop: '-1rem', position: 'relative', zIndex: '1' }}
      >
        <span className="drv-text-bold" data-controller="tooltip" data-tooltip-placement-value="left">
          <span className="drv-info" data-tooltip-target="button" />
          Initial payment:
          <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip">
            Your initial payment is essentially your first monthly instalment, equivalent to a set number of monthly
            rentals. It is not a deposit - it is part of your total lease cost.
            <div className="drv-tooltip__arrow" data-popper-arrow />
          </div>
        </span>
        <span className="">(months) </span>
        <span className="drv-deal-sidebar__option-value drv-text-bold drv-text-gold-darker">
          {price ? (
            <>
              <NumberFormat
                value={initialPayment}
                displayType="text"
                thousandSeparator
                prefix="£"
                fixedDecimalScale
                decimalScale={2}
              />
              {isPersonal ? ' inc' : ' ex'} VAT
            </>
          ) : (
            'Call us'
          )}
        </span>
      </header>
      <div className="drv-radio-buttons drv-mb-6">
        {shortTerm ? (
          <>
            {shortTermInitialPaymentsTotal.map((shortTermInitialPayment) => (
              <Fragment key={shortTermInitialPayment}>
                <label className="drv-radio-button">
                  <input
                    type="radio"
                    name="initial_payment"
                    id={`initial_payment_${shortTermInitialPayment}`}
                    value={shortTermInitialPayment}
                    disabled={!applicableInitialPayments.includes(shortTermInitialPayment)}
                    checked={shortTermInitialPayment === initialMonths}
                    onChange={(event) => {
                      dispatch({
                        type: 'STORE_INITIAL_MONTHS',
                        payload: int(event.target.value),
                      });
                    }}
                  />
                  <span className="drv-radio-button__label">{shortTermInitialPayment}</span>
                </label>
              </Fragment>
            ))}
          </>
        ) : (
          <>
            {applicableInitialPayments.map((applicableInitialPayment) => (
              <Fragment key={applicableInitialPayment}>
                <label className="drv-radio-button">
                  <input
                    type="radio"
                    name="initial_payment"
                    id={`initial_payment_${initialMonths}`}
                    value={applicableInitialPayment}
                    checked={applicableInitialPayment === initialMonths}
                    onChange={(event) => {
                      dispatch({
                        type: 'STORE_INITIAL_MONTHS',
                        payload: int(event.target.value),
                      });
                    }}
                  />
                  <span className="drv-radio-button__label">{applicableInitialPayment}</span>
                </label>
              </Fragment>
            ))}
          </>
        )}
      </div>
    </>
  );
};

MotoringTerms.propTypes = {
  price: PropTypes.number,
  annualMileage: PropTypes.number.isRequired,
  contractLength: PropTypes.number.isRequired,
  initialMonths: PropTypes.number,
  mileages: PropTypes.arrayOf(PropTypes.number).isRequired,
  terms: PropTypes.arrayOf(PropTypes.number).isRequired,
  shortTermMileages: PropTypes.arrayOf(PropTypes.number).isRequired,
  shortTerms: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialPayments: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialPayment: PropTypes.number.isRequired,
  shortTermInitialPaymentsByTerm: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  defaultInitialPaymentMonthsByTerm: PropTypes.objectOf(PropTypes.number).isRequired,
  hasShortTerm: PropTypes.bool.isRequired,
  hasStandardTerm: PropTypes.bool.isRequired,
};

export default MotoringTerms;
